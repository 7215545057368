import React, { useContext, useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

// import axios from "axios";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

import { supabaseClient } from "../supabase";

import { authBackend, publicBackend } from "../Backend";
import SelectSiteModal from "../Auth/selectSiteModal";

import axios from "axios";

const url = window.location.protocol + '//' + window.location.hostname

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');


import jwtDecode from "jwt-decode";
// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";
import { loadRelationships } from "../Relationships/useRelationships";

const SetPassword = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const [formData, setFormData] = useState({});
  const [siteList, setSiteList] = useState([]);

  // const token = localStorage.token && JSON.parse(localStorage.token);
  const navigate = useNavigate();

  let { identifier } = useParams();


  const tokenHash = identifier;

  const loadUser = async (user) => {
    userDispatch({
      type: "SET_CURRENT_USER",
      payload: user,
    });

    await loadRelationships().then((data) => {
      userDispatch({
        type: "SET_RELATIONSHIPS",
        payload: data,
      });
    });

    await loadUsers().then((data) => {
      userDispatch({
        type: "SET_USERS",
        payload: data,
      });
    });

    navigate('/');
  };

  const setLogin = async () => {
    console.log("AutoLogin setting token.", tokenHash);
    // console.log(loginToken);

    const { data, error } = await supabaseClient.auth.verifyOtp({ token_hash: tokenHash, type: 'magiclink' });

    console.log("DATA", data);
    console.log("ERROR", error);

    if (data?.user) {
      const user_data = data.user;
      const { data: profiles } = await supabaseClient.from('profiles').select('id,first_name,last_name,recipient_users(relationship_types(id,name,access_logs, access_schedule))').eq('id', user_data.id);

      var profile = profiles[0];
      profile.relationship = profile.recipient_users[0].relationship_types;

      console.log("USER PROFILE DATA", profile);

      localStorage.setItem("profile", JSON.stringify(profile));
      await loadUser(profile);
      console.log("NAVIGATING TO ROOT")
    } else {
      // console.log("Bad Login");
      // navigate('/login');
    }

    // localStorage.setItem("token", JSON.stringify(loginToken));

    // window.location.assign(`${window.location.protocol}//${window.location.hostname}/home`);
    return;
  }

  useEffect(() => {
    setLogin();
  }, []);

  // console.log("NEW PROFILE 3");
  // console.log(identifier);
  const jwtToken = identifier;

  // const decodeToken = jwtDecode(jwtToken);
  // console.log("JWT DECODE");
  // console.log(decodeToken);

  var ca = jwtToken;
  // var base64Url = ca.split('.')[1];

  // var decodedValue = JSON.parse(window.atob(base64Url));

  // console.log("JWT DECODE 2");
  // console.log(decodedValue);


  const schema = {
    title: "Set New Password",
    // "description": "ThinkBeyond Login",
    type: "object",
    required: ["new_password", "confirm_password"],

    properties: {
      new_password: {
        type: "string",
        format: "password",
        title: "New Password",
        minLength: 3,
      },
      confirm_password: {
        type: "string",
        format: "password",
        title: "Confirm Password",
        minLength: 3,
      },
    },
  };

  const ui_schema = {
    email: {
      "ui:autofocus": true,
      // "ui:description": "Login Username",
    },
    "ui:submitButtonOptions": {
      submitText: "Update",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
  };

  const redirect_user = async (user_data) => { // This is duplicated code from Login

    const { data: { user } } = await supabaseClient.auth.getUser()
    console.log("REDIRECT USER DATA", user);

    const { data: associations_raw } = await supabaseClient.functions.invoke("profile_subdomains",
      {
        body: {
          profiles_id: user.id
        }
      });

    // const { data: associations_raw } = await axios({
    //   url: `${url}/authenticated/profile_subdomains`,
    //   params: { profiles_id: user.id }
    // });

    const associations = associations_raw.filter((row) => row.active == true);
    // const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
    // const base_domain = rest_hostpath.join('.');

    var current_site_association = associations.find((association) =>
      association.subdomain == current_subdomain
    );


    console.log("current_site_association", current_site_association)

    if ((current_site_association !== undefined) && (current_subdomain === current_site_association?.subdomain)) {

      const { data: profiles } = await supabaseClient.from('profiles').select('id,first_name,last_name,recipient_users(relationship_types(id,name,access_logs, access_schedule))').eq('id', user.id);

      var profile = profiles[0];

      profile.relationship = profile.recipient_users[0].relationship_types;

      localStorage.setItem("profile", JSON.stringify({ ...user, ...profile }));

      await loadUser(profile);
      setLoading(false);
      navigate("/");
    } else {

      if (associations.length == 0) {

        await supabaseClient.auth.signOut();

        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: "Login not associated with any active sites.",
          },
        });
        setLoading(false);

      } else if (associations.length == 1) {

        const recipient_subdomain = associations[0].subdomain;

        const { data: magic_link } = await supabaseClient.functions.invoke("getmagiclink",
        {
          body: {
            email: user.email, 
            subdomain: current_subdomain
          }
        });

        // const { data: magic_link } = await axios({
        //   url: `${url}/authenticated/getmagiclink`,
        //   params: { email: user.email, subdomain: current_subdomain }
        // });

        await supabaseClient.auth.signOut();

        const redirect = `${window.location.protocol}//${recipient_subdomain}.${base_domain}/login/${magic_link.hashed_token}`;
        console.log("Redirecting to:", redirect);
        window.location.assign(redirect)

      } else {

        const { data: magic_link } = await supabaseClient.functions.invoke("getmagiclink",
        {
          body: {
            email: user.email, 
            subdomain: current_subdomain
          }
        });

        // const { data: magic_link } = await axios({
        //   url: `${url}/authenticated/getmagiclink`,
        //   params: { email: user.email, subdomain: current_subdomain }
        // });

        for (var i = 0; i < associations.length; i++) {
          associations[i].magic_link = magic_link;
        }

        await supabaseClient.auth.signOut();

        setSiteList(associations);
      }
    }
  }

  const formSubmit = async ({ formData }, e) => {
    // console.log("Data submitted: ", formData);
    // setLoading(true);

    if ('new_password' in formData) {

      if (formData.new_password != formData.confirm_password) {
        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: "Passwords do not match!",
          },
        });
      } else {
        const new_password = formData.new_password;
        const { data, error } = await supabaseClient.auth.updateUser({ password: new_password })
        console.log("SET PASSWORD USER DATA", data);
        delete (formData.new_password);
        delete (formData.confirm_password);
        // setFormData(data);
        if (error) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: error.message,
            },
          });
        } else {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "success",
              display: true,
              text: "Password Updated!"
            },
          });
          redirect_user(data.user);

        }

      }
    } else {
      uiDispatch({
        type: "SET_MESSAGE",
        payload: {
          color: "error",
          display: true,
          text: "Please enter a new password",
        },
      });
    }



    // await publicBackend({
    //   method: "put",

    //   path: `registration/setpassword/`,
    //   payload: { token: token, ...formData },
    // })
    //   .then((data) => {
    //     // setLoading(false);

    //     // console.log("SetPassword formSubmit");
    //     // console.log(data);

    //     uiDispatch({
    //       type: "SET_MESSAGE",
    //       payload: { color: "success", display: true, text: data.message },
    //     });

    //     navigate("/login");
    //   })
    //   .catch((err) => {
    //     // setLoading(false);
    //     // console.log("SetPassword formSubmit ERROR");
    //     // console.log(data);

    //     console.log(err);
    //     if (err && err.response) {
    //       if (err.response.status === 422) {
    //         setError({ ...err.response.data.error });
    //       }

    //       if (err.response.status === 400) {
    //         uiDispatch({
    //           type: "SET_MESSAGE",
    //           payload: {
    //             color: "error",
    //             display: true,
    //             text: err.response.data.error,
    //           },
    //         });
    //       }
    //     }
    //   });

    // try {
    //   const { data } = await axios.put(
    //     `${url}/api/v1/registration/setpassword/${user_id}`,
    //     formData
    //   );

    //   setLoading(false);

    //   uiDispatch({
    //     type: "SET_MESSAGE",
    //     payload: { color: "success", display: true, text: data.message },
    //   });

    //   navigate("/login");
    // } catch (err) {
    //   setLoading(false);

    //   console.log(err);
    //   if (err && err.response) {
    //     if (err.response.status === 422) {
    //       setError({ ...err.response.data.error });
    //     }

    //     if (err.response.status === 400) {
    //       uiDispatch({
    //         type: "SET_MESSAGE",
    //         payload: {
    //           color: "error",
    //           display: true,
    //           text: err.response.data.error,
    //         },
    //       });
    //     }
    //   }
    // }
  };

  return (
    <>
      <SelectSiteModal sitesList={siteList} />

      <Box
        width={"80vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Paper variant="elevation" elevation={2}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            padding={2}
          >
            <Grid item>
              <Form
                formData={formData}
                schema={schema}
                uiSchema={ui_schema}
                validator={validator}
                onSubmit={formSubmit}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default SetPassword;
