import React, { useState, useContext, useEffect } from "react";

import { UserContext } from "../../App";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
import Profile from "./Profile";
import Settings from "../MySenior/SeniorEntry";
import UsersTable from "../Users/UsersTable";
import RelationshipsTable from "../Relationships/RelationshipsTable";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Button } from "@mui/material";

// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';

const url = window.location.protocol + '//' + window.location.hostname
import axios from "axios";

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');


const RegistrationLink = () => {


    const { userState } = useContext(UserContext);

    const handleClick = async (e) => {

        const redirect = `${window.location.protocol}//portal.${base_domain}`;

        const profile = JSON.parse(localStorage.profile);

        console.log("USER EMAIL", userState.currentUser);
        const linkInfo = {
            email: profile.email,
            subdomain: current_subdomain,
            regtype: "existing",
            relationship: "PC",
            redirect: redirect
        }

        const { data: linkData } = await axios({            
            method: "post",
            url: `${url}/public/getregistrationlink`,
            data: linkInfo
        });
        console.log("LINK DATA", linkData);

        const jwtToken = linkData.token;

        // const redirect = 'https://portal.seniorsyncare.com/register'
        console.log("Redirecting to:", (redirect + '/registerrecipient'));
        // window.location.assign(redirect)
        window.open((redirect + '/registerrecipient/' + jwtToken ), "_blank")
    }
    return (
        <><Stack spacing={2}>
            <Typography>Press the button below to register for SeniorSynCare</Typography>
            <Button
                onClick={(e) => { handleClick(e) }}
                variant={"contained"}>
                Register New Senior
            </Button>
        </Stack>
        </>
    );
}

export default RegistrationLink;