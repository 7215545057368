import React, { useState, useEffect, useCallback } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const relationships = ['Friend', 'Colleague', 'Family'];
const roles = ['Admin', 'User', 'Viewer', 'Editor'];

const UsersTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openEditor, setOpenEditor] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [history, setHistory] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    useEffect(() => {
        const handleUndo = (event) => {
            if (event.ctrlKey && event.key === 'z') {
                undo();
            }
        };
        document.addEventListener('keydown', handleUndo);
        return () => {
            document.removeEventListener('keydown', handleUndo);
        };
    }, [history]);

    const addRow = (newRow) => {
        setHistory([...history, { rows }]);
        setRows((prevRows) => [...prevRows, { id: Date.now(), ...newRow }]);
    };

    const updateRow = (id, updatedFields) => {
        setHistory([...history, { rows }]);
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === id ? { ...row, ...updatedFields } : row))
        );
    };

    const deleteRow = (id) => {
        setHistory([...history, { rows }]);
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const undo = () => {
        if (history.length > 0) {
            const previousState = history.pop();
            setRedoStack([...redoStack, rows]);
            setRows(previousState.rows);
            setHistory([...history]);
        }
    };

    const handleOpenEditor = (row) => {
        setSelectedRow(row);
        setOpenEditor(true);
    };

    const handleCloseEditor = () => {
        setSelectedRow(null);
        setOpenEditor(false);
    };

    const handleSaveEditor = (newData) => {
        if (selectedRow) {
            updateRow(selectedRow.id, newData);
        } else {
            addRow(newData);
        }
        handleCloseEditor();
    };

    const handleOpenDeleteConfirm = (row) => {
        setSelectedRow(row);
        setOpenDeleteConfirm(true);
    };

    const handleCloseDeleteConfirm = () => {
        setSelectedRow(null);
        setOpenDeleteConfirm(false);
    };

    const handleConfirmDelete = () => {
        deleteRow(selectedRow.id);
        handleCloseDeleteConfirm();
    };

    const columns = [
        { field: 'firstName', headerName: 'First Name', editable: true, width: 150 },
        { field: 'lastName', headerName: 'Last Name', editable: true, width: 150 },
        {
            field: 'relationship',
            headerName: 'Relationship',
            editable: true,
            width: 150,
            type: 'singleSelect',
            valueOptions: relationships,
        },
        {
            field: 'role',
            headerName: 'Role',
            editable: true,
            width: 150,
            type: 'singleSelect',
            valueOptions: roles,
            renderCell: (params) => {
                console.log('params', params.value, params);
                // params.value?.join(', ')
            },
        },
        { field: 'email', headerName: 'Email', editable: true, width: 200 },
        { field: 'phone', headerName: 'Phone', editable: true, width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => handleOpenEditor(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleOpenDeleteConfirm(params.row)}
                />,
            ],
        },
    ];

    return (
        <>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenEditor(null)}
                style={{ marginBottom: 16 }}
            >
                Add User
            </Button>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableSelectionOnClick
            />

            {openEditor && (
                <UserEditorDialog
                    open={openEditor}
                    onClose={handleCloseEditor}
                    onSave={handleSaveEditor}
                    initialData={selectedRow || {}}
                />
            )}

            {openDeleteConfirm && (
                <DeleteConfirmDialog
                    open={openDeleteConfirm}
                    onClose={handleCloseDeleteConfirm}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </>
    );
};

const UserEditorDialog = ({ open, onClose, onSave, initialData }) => {
    const [formData, setFormData] = useState(initialData);

    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{initialData?.id ? 'Edit User' : 'Add User'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="First Name"
                    value={formData.firstName || ''}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Last Name"
                    value={formData.lastName || ''}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Relationship"
                    select
                    value={formData.relationship || ''}
                    onChange={(e) => handleChange('relationship', e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {relationships.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Role"
                    select
                    multiple
                    value={formData.role || []}
                    onChange={(e) => handleChange('role', e.target.value)}
                    fullWidth
                    margin="normal"
                    //   SelectProps={{ renderValue: (selected) =>  selected.join(', ') }}
                    SelectProps={{
                        renderValue: (selected) => {
                            console.log('selected', selected);
                            // selected.join(', ')

                        }
                    }}


                >
                    {roles.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Email"
                    value={formData.email || ''}
                    onChange={(e) => handleChange('email', e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Phone"
                    value={formData.phone || ''}
                    onChange={(e) => handleChange('phone', e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onSave(formData)} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteConfirmDialog = ({ open, onClose, onConfirm }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this user?</DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} variant="contained" color="secondary">
                Delete
            </Button>
        </DialogActions>
    </Dialog>
);

export default UsersTable;
