import React, { useState, useContext, useEffect } from "react";

import {
  Avatar,
  Grid,
  Stack,
  Typography,
  Box,
  Paper,
  Chip,
  TextField,
  IconButton
} from "@mui/material";

import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { supabaseClient } from "../supabase";

import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

import UserAvatar from "./UserAvatar";

import Speaker from "./Speaker";
import CommentEntry from "./CommentEntry";

import { UserContext } from "../../App";

import {
  loadRelationships,
  RelationshipAvatar,
} from "../Relationships/RelationshipAvatar";

import SharingWidget from "./SharingWidget";

dayjs.extend(utc)
dayjs.extend(timezone)

const user_tz = dayjs.tz.guess()
// const url =
//   process.env.REACT_APP_API_PORT === "80"
//     ? window.location.protocol + "//" + window.location.hostname
//     : window.location.protocol +
//       "//" +
//       window.location.hostname +
//       ":" +
//       process.env.REACT_APP_API_PORT;

const url = window.location.protocol + "//" + window.location.hostname


const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

function Log({ log, ProfilePic, handleCommentSubmit, handleLogEdit }) {
  const [logSharing, setLogSharing] = useState();
  const [logImages, setLogImages] = useState([]);

  const { userState } = useContext(UserContext);

  const getRelationship = (relationship_id) => {
    console.log('getRelationships',relationship_id, userState.relationships);
    return userState.relationships.find((x) => x.id == relationship_id);
  };

  const getUser = (user_id) => {
    return userState.users.find((x) => x.id == user_id);
  };

  // console.log("USERSTATE", userState);

  useEffect(() => {

    setLogSharing({
      share_everyone: log.share_everyone,
      share_relationships: log.share_relationships,
      share_users: log.share_users,
    });

    log.post_images.forEach(async (item) => {
      const { data: image_url } = await supabaseClient.storage.from(item.file.bucket).createSignedUrl(item.file.path, 60);
      setLogImages([...logImages, image_url.signedUrl])
    })
  }, [log]);

  // console.log("LOG DATA", log);

  // const databaseImages = (imagesArray) => {
  //   const promises = imagesArray.map(async (item, index) => {
  //     console.log("IMAGE ITEM", item);
  //     console.log("IMAGE BUCKET", item.file.bucket);
  //     console.log("IMAGE PATH", item.file.path);

  //     const { data: image_url } = await supabaseClient.storage.from(item.file.bucket).createSignedUrl(item.file.path, 60);
  //     // const image_url = "";
  //     console.log("IMAGE URL", image_url)
  //     return (
  //       <Grid key={index} item>
  //         <Box
  //           component="img"
  //           sx={{
  //             maxWidth: { xs: "95%", md: "95%" },
  //           }}
  //           // alt={item.title}
  //           src={image_url.signedUrl}
  //         />
  //       </Grid>
  //     )
  //   })
  //   return Promise.all(promises);
  // }


  return (
    <Paper elevation={3}>
      <Box width="99%" sx={{ padding: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack direction="row" spacing={3}>
              {log.profiles ?
                <>
                  {log.profiles.recipient_users[0] && <>
                    <RelationshipAvatar
                      size={40}
                      relationship_id={log.profiles.recipient_users[0].relationship_types.id}
                    />

                  </>}
                  <Box>
                    <Typography variant="h5">
                      {log.profiles.first_name && (
                        <>{log.profiles.first_name + " " + log.profiles.last_name} </>
                      )}
                      {!log.profiles.first_name && log.phone && (
                        <>{formatPhoneNumber(log.phone)}</>
                      )}
                    </Typography>
                    <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                      {dayjs(log.created_at).tz(user_tz).format("ddd, MMM D, YYYY h:mm A")}
                    </Typography>
                    {/* <p>{dayjs(log.createdAt).format("YYY-mm-ddThh:mm:ss")}</p> */}
                  </Box>
                </>
                : <> <Box>
                  <Typography variant="h5">
                    {log.phone ? (
                      <>{formatPhoneNumber(log.phone)}</>
                    ) : "Unknown"}
                  </Typography>
                  <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                    {dayjs(log.created_at).tz(user_tz).format("ddd, MMM D, YYYY h:mm A")}
                  </Typography>
                  {/* <p>{dayjs(log.createdAt).format("YYY-mm-ddThh:mm:ss")}</p> */}
                </Box></>}
            </Stack>
          </Grid>
          {/* <Grid item xs={1}>
            <Speaker message={log.message} />
          </Grid> */}
          {/* <Grid item xs={1}> */}
          {/* {log.user.id === userState.currentUser.id &&
              <IconButton onClick={() => { handleLogEdit(log.id) }}>
                <FontAwesomeIcon icon={faPen} color="rgb(24,119,242)" />
              </IconButton>} */}
          {/* </Grid> */}
          <Grid
            item
            xs={6}
            align="right"
          // spacing={3}
          // sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Typography variant="h7">
              {dayjs(log.date).tz(user_tz).format("ddd, MMM D, YYYY")}
            </Typography>
            <Stack
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
              spacing={1}
              sx={{ pt: 1, pb: 1 }}
            >
              {log.share_everyone && <Chip label="All Users" size="small" />}
              {log.share_relationships?.split(",").map((id) => {
                return (
                  <>
                    {id ? (
                      <Chip key={id} label={getRelationship(id).name} size="small" />
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              {log.share_users?.split(",").map((id) => {
                const my_user = getUser(id);
                // console.log("MY USER", my_user);
                return (
                  <>
                    {my_user ? (
                      <Chip
                        key={id}
                        label={my_user.profiles.first_name + " " + my_user.profiles.last_name}
                        size="small"
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </Stack>
          </Grid>
          {/* <Grid
            item
            xs={5}
            align="left"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <SharingWidget
              value={logSharing}
              onChange={setLogSharing}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={2} align="right">
            <TextField
              label="Date"
              defaultValue={dayjs(log.date).format("YYYY-MM-DD")}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid> */}
        </Grid>
      </Box>

      <Grid item container spacing={2} style={{ margin: "2px 2px 2px 2px" }}>
        {logImages && logImages.map((item, index) => (
          <Grid key={index} item>
            <Box
              component="img"
              sx={{
                maxWidth: { xs: "95%", md: "95%" },
              }}
              // alt={item.title}
              src={item}
            />
          </Grid>
        ))




          // log.post_images.map(async (item, index) => {
          //   console.log("IMAGE ITEM", item);
          //   console.log("IMAGE BUCKET", item.file.bucket);
          //   console.log("IMAGE PATH", item.file.path);

          //   const { data: image_url } = await supabaseClient.storage.from(item.file.bucket).createSignedUrl(item.file.path, 60);
          //   // const image_url = "";
          //   console.log("IMAGE URL", image_url)
          //   return (
          //     <Grid key={index} item>
          //       <Box
          //         component="img"
          //         sx={{
          //           maxWidth: { xs: "95%", md: "95%" },
          //         }}
          //         // alt={item.title}
          //         src={image_url.signedUrl}
          //       />
          //     </Grid>
          //   )
          // })

        }
      </Grid>

      {log.message && (
        <Stack direction="row">
          <Speaker message={log.message} />
          <Box sx={{ width: "100%" }}>
            <Paper style={{ margin: "8px 8px 8px 8px" }}>
              <Typography
                fullWidth
                style={{
                  margin: "10px 10px 10px 10px",
                  whiteSpace: "pre-line",
                }}
                variant="body1"
              >
                {log.message}
              </Typography>
            </Paper>
          </Box>
        </Stack>
      )}
      {log.comments &&
        log.comments.map((comment) => (
          <Paper
            key={Math.random()}
            elevation={3}
            style={{
              margin: "8px 8px 8px 20px",
              background: "#fffde7",
            }}
          >
            <Stack direction="row" spacing={3}>
              {log.profiles && log.profiles.recipient_users[0] && <> <RelationshipAvatar
                size={30}
                relationship_id={comment.profiles.recipient_users[0].relationship_types.id}
              />
              </>}
              {/* <UserAvatar user={comment.user} /> */}
              {/* <Avatar src={ProfilePic} style={{ fontSize: "1.5rem" }} /> */}
              <Typography style={{ fontWeight: 500 }}>
                {comment.profiles.first_name + " " + comment.profiles.last_name}
              </Typography>
              <p>
                {dayjs(comment.created_at).tz(user_tz).format("ddd, MMM D, YYYY h:mm A")}
              </p>
            </Stack>
            <Stack direction="row">
              <Speaker message={comment.message} />
              <Typography
                style={{
                  margin: "10px 10px 10px 10px",
                  whiteSpace: "pre-line",
                }}
                variant="body1"
              >
                {comment.message}
              </Typography>
            </Stack>
          </Paper>
        ))}
      <CommentEntry
        post={log}
        // post_id={log.id}
        key={log.id}
        onSubmit={handleCommentSubmit}
      />
    </Paper>
  );
}

export default Log;
