import React, { useState, useContext, useEffect } from "react";

import { UserContext } from "../../App";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
import Profile from "../Auth/Profile";
import Settings from "../MySenior/SeniorEntry";
import UsersTable from "../Users/UsersTable";
import RelationshipsTable from "../Relationships/RelationshipsTable";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ServicesTable from "../MySenior/ServicesTable";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';


const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');

const SeniorSettings = ({ themeFontSize, onFontSizeChange }) => {
    const { userState } = useContext(UserContext);
    const isPC = (userState.currentUser.relationship.name == "PC");

    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Stack spacing={2}>
                {/* <ServicesTable />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}> */}
                        <Settings />
                    {/* </Grid>
                </Grid> */}
            </Stack>
        </Box >
    );
};
export default SeniorSettings;
