import React, { useState, useEffect, useCallback } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Autocomplete,
    Checkbox,
    Stack,
    Tooltip,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import { supabaseClient } from "../supabase";


export const UserEditorDialog = ({ open, onClose, onSave, initialData = {}, relationships, roles = [] }) => {
    const [formData, setFormData] = useState(initialData);
    const noErrors = { firstName: "", lastName: "", relationship: "", email: "", phone: "" };

    const [errors, setErrors] = useState(noErrors);
    const [isValidating, setIsValidating] = useState(false);

    const [openRoleEditor, setOpenRoleEditor] = useState(false);

    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleNewRoleSave = (newRole) => {

        const maxId = roles.reduce((max, dict) => Math.max(max, dict.id), 0);
        newRole.id = maxId + 1;

        setFormData((prevData) => ({ ...prevData, role: prevData.role ? [...prevData.role, newRole] : [newRole] }));

        setOpenRoleEditor(false)
    };


    const validate = async () => {
        const newErrors = { ...noErrors };
        var isValid = true;

        if (formData.firstName.trim().length < 2) {
            newErrors.firstName = "Name must be at least 2 characters long.";
            isValid = false;
        }
        if (formData.lastName.trim().length < 2) {
            newErrors.lastName = "Name must be at least 2 characters long.";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
        }
        else if (initialData?.email === formData.email.trim()) {
        }
        else if (!emailRegex.test(formData.email.trim())) {
            newErrors.email = "Invalid email format.";
            isValid = false;
        } else {
            setIsValidating(true);
            const { data: users, error } = await supabaseClient
                .from("community")
                .select("id,email")
                .eq("email", formData.email.trim());

            if (error) {
                console.error("Error fetching users:", error);
                newErrors.email = "Error checking email availability. Please try again.";
                isValid = false;
            } else if (users.length > 0) {
                newErrors.email = "Member Email is already a community member.";
                isValid = false;
            }
            setIsValidating(false);
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (formData) => {
        if (await validate(formData)) {
            const { data: users, error } = await supabaseClient
                .from("profiles")
                .select("id,username")
                .eq("username", formData.email.trim());

            if (error) {
                console.error("Error fetching users:", error);
                newErrors.email = "Error checking email availability. Please try again.";
            } else if (users.length > 0) {
                formData.profiles_id = users[0].id;
                const relationship = relationships.find((r) => r.name === formData.relationship);
                if (member.profiles_id) {
                    supabaseClient.from('recipient_users').update({ 'active': true, 'relationship_types_id': relationship.id }).eq('profiles_id', formData.profiles_id).eq('recipients_id', recipients_id)
                }
                if (initialData?.email !== formData.email.trim()) {
                    
                }
            }
            onSave(formData);
            setErrors(noErrors);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{initialData?.id ? 'Edit User' : 'Add User'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="First Name"
                        value={formData.firstName || ''}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                    />
                    <TextField
                        label="Last Name"
                        value={formData.lastName || ''}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                    />
                    <TextField
                        label="Relationship"
                        select
                        value={formData.relationship || ''}
                        onChange={(e) => handleChange('relationship', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.relationship}
                        helperText={errors.relationship}
                    >
                        {relationships?.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    {roles.length > 0 && <Autocomplete
                        multiple
                        id="roles-standard"
                        options={roles}
                        getOptionLabel={(option) => option.ServiceNeeds}
                        // getOptionValue={(option) => option.ServiceNeeds}
                        //   defaultValue={[sharingOptions[0]]}
                        value={formData?.role || []}
                        defaultValue={[]}
                        // readOnly={readOnly}
                        limitTags={4}
                        // groupBy={(option) => {
                        //   option.group;
                        // }}
                        disableCloseOnSelect
                        // onChange={(e, value) => { handleChange(value) }}
                        onChange={(event, newValue) => handleChange('role', newValue)}
                        renderOption={(props, option, { selected }) => (

                            <li {...props}>
                                <Checkbox
                                    // icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.ServiceNeeds}
                                {/* {option.title} */}
                            </li>
                        )}
                        renderInput={(params) => (
                            <Stack direction={"row"} spacing={1}>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={"Roles"}
                                />
                                <Tooltip title="New Role...">
                                    <IconButton edge="end" color="primary"
                                        onClick={() => { setOpenRoleEditor(true) }}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        )}
                    // renderGroup={(params) => (
                    //   <li key={params.key}>
                    //     <GroupHeader>{params.group}</GroupHeader>
                    //     <GroupItems>{params.children}</GroupItems>
                    //   </li>
                    // )}
                    />
                    }

                    <TextField
                        label="Email"
                        value={formData.email || ''}
                        onChange={(e) => handleChange('email', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        label="Phone"
                        value={formData.phone || ''}
                        onChange={(e) => handleChange('phone', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.phone}
                        helperText={errors.phone}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => handleSubmit(formData)} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <NewRoleEditorDialog
                open={openRoleEditor}
                onClose={() => setOpenRoleEditor(false)}
                onSave={handleNewRoleSave} />
        </>
    );
};


const NewRoleEditorDialog = ({ open, onClose, onSave }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "600px!important",
                    },
                }}
            >
                <DialogTitle>Add Role</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Role"
                        value={formData.ServiceNeeds || ''}
                        onChange={(e) => handleChange('ServiceNeeds', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Notes"
                        value={formData.Notes || ''}
                        onChange={(e) => handleChange('Notes', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => onSave(formData)} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};