import { useState, useEffect } from "react";

import { parseISO, addDays } from "date-fns";
import { getInitialRepeat } from "./useRepeat";

import { confirmEvent, categories, deleteEvent } from "./useSchedule";

import { DateField, SelectField } from "./ScheduleFields";

import { RepeatFields } from "./RepeatFields";

import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TabPanel,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import SharingWidget from "../Logs/SharingWidget";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { supabaseClient } from "../supabase";

dayjs.extend(dayjsPluginUTC);

const EventWidget = ({ event, onClose, onConfirm, action }) => {
  const [error, setError] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const recipient_id = localStorage.recipient_id;

  const handleDelete = async () => {
    // console.log("Delete Event ", event);

    if (event.repeat.ics !== "") {
      setDeleteDialogOpen(true);
    } else {
      deleteEvent(event.event_id, event.db_id).then((deleted_event) => {
        onConfirm(deleted_event, "edit");
      });
      onClose();
    }
  };

  // console.log("OPEN EVENT", event);

  const [state, setState] = useState({
    title: event?.title || "",
    repeat: event?.repeat || getInitialRepeat(event.start, event.end),
    description: event?.description || "",
    start: event.start,
    end: event.end,
    // start: event?.start,
    // end: event?.end,
    db_id: event?.db_id || undefined,
    duration: (event.end - event.start) / 1000,
    type: event?.type || 0,
    sharing:
      action === "edit"
        ? event.sharing
        : {
          share_everyone: false,
          share_relationships: ",,",
          share_users: ",,",
        },
  });

  const handleChange = (value, name) => {
    const newState = { ...state, [name]: value };

    if (name === "start") {
      const new_end = dayjs(value)
        .add(state.duration, 'seconds').toDate();
      newState.end = new_end;
    }
    if (name === "type" && categories[value].name === "Available") {
      newState.title = "Available";
    }

    newState.duration = (newState.end - newState.start) / 1000;
    setState(newState);
  };

  const handleSubmit = async () => {
    // Your own validation
    if (state.title.length < 2) {
      return setError("Min 2 letters");
    }

    try {
      // scheduler.loading(true);

      const added_updated_event = await confirmEvent(
        {
          event_id: event ? event.event_id : 1,
          db_id: event ? event.db_id : 1,
          start: state.start,
          end: state.end,
          title: state.title,
          description: state.description,
          duration: state.duration,
          repeat: state.repeat,
          type: state.type,
          sharing: state.sharing,
        },
        action
      );

      if (onConfirm) {
        onConfirm(added_updated_event, action);
      }
      onClose();
    } finally {
      // scheduler.loading(false);
    }
  };

  const handleDeleteOccurrence = () => {
    console.log("Deleting single occurrence...");

    var payload;
    var icsString = "";
    const rruleSet = event.repeat.rruleSet;

    var today = new Date();
    const today_offset = today.getTimezoneOffset();
    const gmt_offset = event.start.getTimezoneOffset();
    const tz_offset = (today_offset - gmt_offset) / 60
    var new_date = new Date(event.start)
    new_date.setHours(new_date.getHours() + tz_offset)

    rruleSet.exdate(new_date);
    icsString = rruleSet.toString();

    payload = {
      repeat: true,
      ics: icsString,
    };

    console.log("DELETE OCCURRENCE PAYLOAD", payload);

    supabaseClient.from("events").update(payload).eq('id', event.db_id).select().single().then((data) => {
      console.log("DELETE OCCURRENCE RESULT", data);
    });

    setDeleteDialogOpen(false);
    if (onConfirm) {
      onConfirm(event.db_id, "edit");
    }
    onClose();
  };

  const handleDeleteSeries = () => {
    console.log("Deleting entire series...");

    deleteEvent(event.event_id, event.db_id).then((deleted_event) => {
      onConfirm(deleted_event, "edit");
    });
    setDeleteDialogOpen(false);
    onClose();
  };

  return (
    <>
      <Box sx={{ m: 2, maxWidth: "md" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="Title"
              disabled={state.type === 3} // disabled with Available
              value={
                typeof state.title === "string"
                  ? state.title
                  : state.title.props?.children[2]
              }
              onChange={(e) => handleChange(e.target.value, "title")}
              error={!!error}
              helperText={error}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              label={"Type"}
              config={state}
              prop={"type"}
              onChange={handleChange}
              selectList={categories}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={"Starting on"}
              config={state}
              prop={"start"}
              onChange={handleChange}
            // maxDate={state.end}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={"And Ends"}
              config={state}
              prop={"end"}
              onChange={handleChange}
              minDate={state.start}
            />
          </Grid>
          <RepeatFields
            start={state.start}
            end={state.end}
            value={state.repeat}
            onChange={(x) => {
              handleChange(x, "repeat");
            }}
          />
          <Grid item xs={12}>
            <SharingWidget
              fullWidth
              label={"Send Email Updates To..."}
              value={state.sharing}
              // onChange={(sharing) => {
              //   console.log("SHARING UPDATE", sharing);
              // }}
              onChange={(sharing) => {
                handleChange(sharing, "sharing");
              }}
            // value={logSharing}
            // onChange={setLogSharing}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={state.description}
              onChange={(e) => handleChange(e.target.value, "description")}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>{" "}
          <Grid item xs={12}>
            <DialogActions>
              {action === "edit" && (
                <Button color="error" onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSubmit}>Confirm</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Box>
      <DeleteEventDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDeleteOccurrence={handleDeleteOccurrence}
        onDeleteSeries={handleDeleteSeries}
      />
    </>
  );
};


const DeleteEventDialog = ({ open, onClose, onDeleteOccurrence, onDeleteSeries }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Event</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Do you want to delete this occurrence or the entire series of events?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeleteOccurrence} color="primary">
          Delete Occurrence
        </Button>
        <Button onClick={onDeleteSeries} color="error">
          Delete Series
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export default EventWidget;
