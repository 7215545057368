import React, { useContext } from "react";

import { Link as RouterLink, useLocation, NavLink } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Container,
  Divider,
  Grid,
  Paper,
  Link,
  Typography
} from "@mui/material";

import "./NavbarPage.css";
import { UserContext } from "../../App";


const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  display: 'flex',
  borderColor: 'primary.main',
  borderStyle: 'solid',
  borderTop: 0,
  borderRight: 0,
  borderLeft: 0,
};

const NavbarPage = ({ recipient }) => {
  const { userState, userDispatch } = useContext(UserContext);

  var location = useLocation();

  return (
    <>
      <Grid container direction="row">
        <Grid item align="left" alignItems="flex-end" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant='h4' style={{ color: "#083D77" }}>
            <strong>{recipient.nickname} Support Website</strong>
          </Typography>
        </Grid>
        <Grid item align="left" alignItems="flex-end" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant='h6' style={{ color: "#242420" }}>
            <strong> - Dignity, grace and compassion</strong>
          </Typography>
        </Grid>
      </Grid>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >

        <Grid container spacing={1} direction="row">
          <Grid item align="left">
            <Box sx={{ ...commonStyles, borderBottom: (location.pathname === "/home" ? undefined : 0) }}>
              <Link component={RouterLink} color="#083D77" variant="h6" to="/home">Home</Link>
            </Box>
          </Grid>
          <Grid item align="left">
            <Box sx={{ ...commonStyles, borderBottom: (location.pathname === "/calendar" ? undefined : 0) }}>
              <Link component={RouterLink} color="#083D77" variant="h6" to="/calendar">Calendar</Link>
            </Box>
          </Grid>
          <Grid item align="right">
            <Box sx={{ ...commonStyles, borderBottom: 0 }}>
              <Link component={RouterLink} color="#083D77" variant="h6" to="/login">
                {userState.isLoggedIn ? "" : "Login"}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NavbarPage;
