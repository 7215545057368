import React, { useState } from 'react';
import {
    Stack,
    Paper,
    Tooltip,
    Typography,
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    MenuItem,
    Box
} from '@mui/material';
import { blue, red, green } from '@mui/material/colors'; // Example colors
// import IconPicker from './IconPicker'; // Assuming you have a custom IconPicker component

import { RelationshipAvatar } from '../Relationships/RelationshipAvatar';

const RelationshipList = ({ relationships, onUpdateRelationship }) => {

    const [selectedRelationship, setSelectedRelationship] = useState(null);

    const handleOpenEditor = (relationship) => {
        console.log('handleOpenEditor', relationship);
        // setSelectedRelationship(relationship);
    };

    const handleCloseEditor = () => {
        setSelectedRelationship(null);
    };

    const handleSave = () => {
        if (selectedRelationship) {
            onUpdateRelationship(selectedRelationship);
        }
        handleCloseEditor();
    };

    const handleFieldChange = (field, value) => {
        setSelectedRelationship((prev) => ({ ...prev, [field]: value }));
    };

    console.log("relationships", relationships);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Stack direction="row" spacing={3}>
                {relationships.map((relationship, index) => (
                    <Tooltip key={index} title={relationship.description} arrow>
                        <Paper
                            elevation={3}
                            sx={{
                                width: 250,
                                padding: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleOpenEditor(relationship)}
                        >
                            <Box sx={{ width: 30, height: 30, marginRight: 1 }}>
                                <RelationshipAvatar
                                    size={30}
                                    configString={relationship.icon}
                                />
                            </Box>
                            {/* <Avatar sx={{ bgcolor: relationship.iconColor || 'primary.main', marginRight: 1 }}

                                // configString={relationship.icon}
                            >{relationship.icon}
                            </Avatar> */}
                            <Typography variant="body2">{relationship.name}</Typography>
                        </Paper>
                    </Tooltip>
                ))}

                {selectedRelationship && (
                    <RelationshipEditorDialog
                        open={!!selectedRelationship}
                        relationship={selectedRelationship}
                        onClose={handleCloseEditor}
                        onSave={handleSave}
                        onFieldChange={handleFieldChange}
                    />
                )}
            </Stack>
        </Box>
    );
};

const RelationshipEditorDialog = ({ open, relationship, onClose, onSave, onFieldChange }) => {
    const colors = [blue[500], red[500], green[500]]; // Example color options

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Relationship</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={relationship.name || ''}
                    onChange={(e) => onFieldChange('name', e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    value={relationship.description || ''}
                    onChange={(e) => onFieldChange('description', e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    select
                    label="Icon Color"
                    value={relationship.iconColor || ''}
                    onChange={(e) => onFieldChange('iconColor', e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {colors.map((color) => (
                        <MenuItem key={color} value={color}>
                            <Avatar sx={{ bgcolor: color, marginRight: 1 }} /> {color}
                        </MenuItem>
                    ))}
                </TextField>
                {/* <IconPicker
          selectedIcon={relationship.icon}
          onChange={(icon) => onFieldChange('icon', icon)}
        /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RelationshipList;
