

export const DefaultServiceNeeds = [
    {
        "id": 1,
        "Notes": "",
        "ServiceNeeds": "Getting in and out of bed"
    },
    {
        "id": 2,
        "Notes": "",
        "ServiceNeeds": "Dressing"
    },
    {
        "id": 3,
        "Notes": "",
        "ServiceNeeds": "Grooming"
    },
    {
        "id": 4,
        "Notes": "",
        "ServiceNeeds": "Toileting"
    },
    {
        "id": 5,
        "Notes": "",
        "ServiceNeeds": "Eating"
    },
    {
        "id": 6,
        "Notes": "",
        "ServiceNeeds": "Walking"
    },
    {
        "id": 7,
        "Notes": "",
        "ServiceNeeds": "Getting out of chair"
    },
    {
        "id": 8,
        "Notes": "",
        "ServiceNeeds": "Preparing Meals"
    },
    {
        "id": 9,
        "Notes": "",
        "ServiceNeeds": "Doing laundry"
    },
    {
        "id": 10,
        "Notes": "",
        "ServiceNeeds": "Light housework"
    },
    {
        "id": 11,
        "Notes": "",
        "ServiceNeeds": "Shopping "
    },
    {
        "id": 12,
        "Notes": "",
        "ServiceNeeds": "Transportation"
    },
    {
        "id": 13,
        "Notes": "",
        "ServiceNeeds": "Using telephone"
    },
    {
        "id": 14,
        "Notes": "",
        "ServiceNeeds": "Managing money"
    },
    {
        "id": 15,
        "Notes": "",
        "ServiceNeeds": "Administering medications "
    },
    {
        "id": 16,
        "Notes": "",
        "ServiceNeeds": "Exercise"
    },
    {
        "id": 17,
        "Notes": "",
        "ServiceNeeds": "Social Events"
    },
    {
        "id": 18,
        "Notes": "",
        "ServiceNeeds": "Making and attending medical appointments"
    },
    {
        "id": 19,
        "Notes": "",
        "ServiceNeeds": "Emotional/Spiritual"
    }
]