import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import AdbIcon from "@mui/icons-material/Adb";
import { UserContext } from "../App";

const drawerWidth = 240;

import { v4 as uuidV4 } from "uuid";

function ResponsiveDrawer(props) {
  const { window, menuItems } = props;
  const { userState } = useContext(UserContext);
  // const [mobileOpen, setMobileOpen] = React.useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  const relationship_id = userState.currentUser.relationship.id;

  const RouterLink = React.forwardRef((props, ref) => {
    const [linkKey, setLinkKey] = useState(uuidV4());

    return (
      <NavLink
        onClick={() => {
          setLinkKey(uuidV4());
        }}
        key={linkKey}
        // to={"/users"}
        state={{ key: linkKey }}
        ref={ref}
        {...props}
        className={({ isActive }) =>
          `${props.className} ${isActive ? "Mui-selected" : props.ClassName}`
        }
      />
    );
  });

  const MenuItemList = ({ menuItems }) => {
    return (
      <div>
        {/* <Toolbar /> */}
        {/* <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
        /> */}

        {/* <Divider /> */}
        <List>
          {menuItems
            .filter((item) => item.allowed)
            .map((item, index) => (
              <ListItem
                key={uuidV4()}
                disablePadding
                component={RouterLink}
                to={item.path}
              >
                <ListItemButton>
                  <ListItemIcon color='secondary'>{item.icon}</ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: { color: '#242420' } }} primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </div>
    );
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    // <Box sx={{ display: 'flex' }}>
    <>
      <CssBaseline />
      <Box
        component="nav"
        // sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            // display: { xs: "none", sm: "block" },
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box
            component="img"
            sx={{
              m: 2,
              ml: 4,
              // height: 150,
              width: 150,
              // maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            // alt="The house from the offer."
            // src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
            // src="/Icon_SingleHeart_Flat.png"
            // src="/Balloon_3D.png"
            // src="/Balloon_Flat.png"
            src="/SSC_Swan_Logo.png"
          />

          <MenuItemList menuItems={menuItems} />
        </Drawer>
      </Box>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
