import React, { useState, useEffect, useContext } from "react";

import { Stack, Typography, Box, listItemTextClasses } from "@mui/material";
import ScrollToTop from "./ScrollToTop";

import Log from "./Log";
import LogEntry from "./LogEntry";
import { SearchBar } from "./SearchBar";

// https://github.com/MertCankaya/Facebook-Clone/blob/main/src/Feed.js

import { Collapse, Button } from "@mui/material";

import { UserContext } from "../../App";
import { authBackend } from "../Backend";

import { supabaseClient } from "../supabase";

import { RelationshipAvatar } from "../Relationships/RelationshipAvatar";

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');

async function loadUsers(current_user_id) {
  const recipient_id = localStorage.recipient_id;

  return await authBackend({
    path: "user/",
    filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
  })
    .then((data) => {
      const result_out = data.result
        .map((item, index) => ({
          ...item,
          id: data.ids[index],
          relationship: item.relationship,
          // document_type: item.document_type.name,
        }))
        .filter((item) => item.id != current_user_id);
      // // setData({ ...data, result: result_out });
      return result_out;
    })
    .catch((err) => {
      return err;
    });
}

const fetchAllPosts = async (pageNum = 0, searchQuery = "") => {
  // let token = JSON.parse(localStorage.token);

  let recipient_id = localStorage.recipient_id;

  let profile = JSON.parse(localStorage.profile); // should use USerContext Instead!

  console.log("PROFILE", profile);

  const recipient_users_id = profile.recipient_users[0].id

  // filters.push({ col: "recipient", opr: "rel_o_m", value: recipient_id });
  // filters.push({ col: "recipient", opr: "share", value: recipient_id });
  // if (user_id) {
  //   filters.push({ col: "user", opr: "eq", value: user_id });
  // }

//   [
//     {
//         "relationship_types": {
//             "id": 168,
//             "name": "Family",
//             "access_logs": 1,
//             "access_schedule": 0
//         }
//     }
// ]

// [
//   {
//       "id": 81,
//       "relationship_types": {
//           "id": 168,
//           "name": "Family",
//           "access_logs": 1,
//           "access_schedule": 0
//       }
//   }
// ]

  const page_size = 20;

  if (!pageNum) { pageNum = 0; }

  console.log("POST FILTERS", searchQuery);

  const { data: recipients } = await supabaseClient.from('recipients').select('*');

  var query_string = '*'
    + ',post_images(id,file)'
    + ',profiles(id,first_name,last_name'
    + ',recipient_users(id,relationship_types(id,name)))'
    + ',comments(id,created_at,message'
    + ',profiles(id,first_name,last_name'
    + ',recipient_users(id,relationship_types(id,name)))'
    + ')';

  // var posts, posts_count;
  var response
  var posts_ids = []
  var posts = [];
  var posts_count = 0;

  if (searchQuery && searchQuery != "") {

    query_string = '*'
      + ',post_images(id,file)'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ',comments(id,created_at,message'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ')';

    response = await supabaseClient.from('posts')
      .select(query_string, { count: 'planned' })
      .or(`message.ilike.%${searchQuery}%,meta_text.ilike.%${searchQuery}%`)
      .order('created_at', { ascending: false })
      .range(pageNum * page_size, ((pageNum + 1) * page_size));

    response.data.forEach((item) => {
      if (!posts_ids.includes(item.id)) {
        posts_ids.push(item.id);
        posts.push(item);
        posts_count++;
      }
    });

    query_string = '*'
      + ',post_images(id,file)'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ',comments!inner(id,created_at,message'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ')';

    let sb_query = supabaseClient.from('posts')
      // .select(query_string, { count: 'planned' })
      .select(query_string, { count: 'exact' })

      .or(`message.ilike.%${searchQuery}%,meta_text.ilike.%${searchQuery}%`, { referencedTable: 'comments' })
      .order('created_at', { ascending: false })
      .range(pageNum * page_size, ((pageNum + 1) * page_size));

      if (profile.relationship.name == "PC"){
        response = await sb_query
      }else{
        response = await sb_query.or(`share_relationships.ilike."%,${profile.relationship.id},%",share_users.ilike."%,${recipient_users_id},%",share_everyone.eq.TRUE`)
      }
  


    response.data.forEach((item) => {
      if (!posts_ids.includes(item.id)) {
        posts_ids.push(item.id);
        posts.push(item);
        posts_count++;
      }
    });

    posts.sort((a, b) => b.id - a.id);

  } else {

    query_string = '*'
      + ',post_images(id,file)'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ',comments(id,created_at,message'
      + ',profiles(id,first_name,last_name'
      + ',recipient_users(id,relationship_types(id,name)))'
      + ')';

    let sb_query = supabaseClient.from('posts')
    // .select(query_string, { count: 'planned' })
    .select(query_string, { count: 'exact'})

    
    .order('created_at', { ascending: false })
    // .or(`share_relationships.ilike.%,${profile.relationship.id},%,share_users.ilike.%,${searchQuery}%,`, { referencedTable: 'comments' })
    //.or(,)
    // .like('share_relationshps',`,${profile.relationship.id}`)
    .range(pageNum * page_size, ((pageNum + 1) * page_size));

    if (profile.relationship.name == "PC"){
      response = await sb_query
    }else{
      response = await sb_query.or(`share_relationships.ilike."%,${profile.relationship.id},%",share_users.ilike."%,${recipient_users_id},%",share_everyone.eq.TRUE`)
    }

    // response = await supabaseClient.from('posts')
    //   .select(query_string, { count: 'planned' })
    //   .order('created_at', { ascending: false })
    //   // .or(`share_relationships.ilike.%,${profile.relationship.id},%,share_users.ilike.%,${searchQuery}%,`, { referencedTable: 'comments' })
    //   .or(`share_relationships.ilike.%${profile.relationship.id}%,share_everyone.eq.TRUE`)
    //   //.or(,)
    //   // .like('share_relationshps',`,${profile.relationship.id}`)
    //   .range(pageNum * page_size, ((pageNum + 1) * page_size));
    posts = response.data;
    posts_count = response.count;
  }

  const totalPages = Math.floor(posts_count / page_size);
  // console.log("POSTS COUNT", posts_count);
  
  return {
    recipient: recipients[0],
    posts: posts,
    pagination: {
      // totalPage: Math/floor(posts_count / page_size),
      totalPage: totalPages,
      currentPage: pageNum > totalPages ? totalPages : pageNum
    }
  }


  // return authBackend({
  //   path: "post/",
  //   filters: filters,
  //   order_column: "created_at",
  //   order_direction: "desc",
  //   page: pageNum,
  //   page_size: page_size,
  // })
  //   .then((data) => {
  //     // console.log("fetchAllPosts");
  //     // console.log(data);
  //     return {
  //       recipient: data.recipient,
  //       posts: data.result,
  //       pagination: {
  //         totalPage: Math.ceil(data.count / page_size),
  //         currentPage: pageNum,
  //       },
  //     };
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  // try {
  //   const response = await axios.get(`${url}/api/v1/post/?q=${querystring}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });

  //   // console.log("POST LOGS");
  //   // console.log(response);

  //   if (response.data.result) {
  //     return {
  //       posts: response.data.result,
  //       pagination: {
  //         totalPage: Math.ceil(response.data.count / querystring.page_size),
  //         currentPage: querystring.pageNum,
  //       },
  //     };
  //   }
  // } catch (err) {
  //   console.log(err);
  // }
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export default function Logs({ ProfilePic }) {
  const { userState } = useContext(UserContext);
  const [logentry, setLogEntry] = useState(false);
  const [logs, setLogs] = useState({ posts: [], pagination: {} });
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateLogId, setUpdateLogId] = useState(0);

  console.log("LOGS USER STATE", userState);


  useEffect(() => {
    fetchAllPosts(page).then((res) => {
      setLogs(res);
    });
  }, []);

  const handleFetchPosts = (currentPage) => {

    

    // setPage(currentPage + 1);
    fetchAllPosts(currentPage + 1, searchQuery,
      //   [
      //   {
      //     col: "message",
      //     opr: "ctx",
      //     value: searchQuery,
      //   },
      // ]
    ).then((res) => {

      // console.log("LOGS RESULT");
      // console.log(res);
      if (res.posts) {
        setLogs({
          recipient: res.recipient,
          posts: res.posts ? [...logs.posts, ...res.posts] : logs.posts,
          pagination: res.pagination,
        });
        setPage(res.pagination.currentPage);
      }
    });
  };

  const handleCreateEntry = () => {
    setLogEntry(!logentry);
    setUpdateLogId(0);
  };

  const handleSubmitEntry = (newPost) => {
    console.log("LOGS HANDLE CREATE POST", newPost);

    setLogEntry(!logentry);
    setLogs({
      posts: [newPost, ...logs.posts],
      pagination: logs.pagination,
    });
    setUpdateLogId(0);
  };

  const handleCommentSubmit = () => {
    setPage(0);
    // fetchAllPosts(page,
      fetchAllPosts(0,
      searchQuery
      //   [
      //   {
      //     col: "message",
      //     opr: "ctx",
      //     value: searchQuery,
      //   },
      // ]
    ).then((res) => {
      setLogs(res);
    });
  };

  const doSearch = (searchText) => {
    setSearchQuery(searchText)
    setPage(0);
    fetchAllPosts(page,
      searchText

      //   [
      //   {
      //     col: "message",
      //     opr: "ctx",
      //     value: searchText,
      //   },
      // ]
    ).then((res) => {
      setLogs(res);
    });
  };

  const handleSearch = () => {
    setPage(0);
    fetchAllPosts(page,
      searchQuery
      //   [
      //   {
      //     col: "message",
      //     opr: "ctx",
      //     value: searchQuery,
      //   },
      // ]
    ).then((res) => {
      setLogs(res);
    });
  };

  const handleLogEdit = (log_id) => {
    setUpdateLogId(log_id)
  }

  useEffect(() => {
    const onScroll = (e) => {
      const scroll_actual =
        e.target.documentElement.scrollHeight -
        Math.round(e.target.documentElement.scrollTop);
      const scroll_target = e.target.documentElement.clientHeight + 200;
      const bottom = scroll_actual < scroll_target;

      if (bottom) {        
        handleFetchPosts(logs.pagination.currentPage);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [logs]);

  return (
    <>
      {/* <h2>Logs</h2> */}
      {/* Logged in as user {userState.currentUser.name} */}
      <Box>
        <Box align="center">
          {logs.recipient && current_subdomain.startsWith('cop-') && (<Typography variant="h6" sx={{ pb: 2 }}>SeniorSynCare Community of Practice - {logs.recipient.last_name}</Typography>)}
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={doSearch}
          />
        </Box>
        <p>
          {logs.recipient && logs.recipient.post_texting_number && (
            <>
              You can also create a log by texting to{" "}
              {formatPhoneNumber(logs.recipient.post_texting_number)}
            </>
          )}
        </p>
        <Stack spacing={2}>
          <Button
            onClick={handleCreateEntry}
            variant="contained"
            color="primary"
            style={{ width: "100%" }}
          >
            Create Message
          </Button>
          <Collapse in={logentry}>
            <LogEntry
              timestamp={"createdNow"}
              user={userState.currentUser}
              // username={userState.currentUser.name}
              // username={"changeme"}
              handleClose={handleCreateEntry}
              handleSubmit={handleSubmitEntry}
            />
          </Collapse>

          {logs.posts.map((log) => (
            updateLogId === log.id ?
              <LogEntry
                initialData={log}
                timestamp={"createdNow"}
                user={userState.currentUser}
                // username={userState.currentUser.name}
                // username={"changeme"}
                handleClose={handleCreateEntry}
                handleSubmit={handleSubmitEntry}
              />
              :
              <Log
                key={log.id}
                log={log}
                ProfilePic={ProfilePic}
                handleCommentSubmit={handleCommentSubmit}
                handleLogEdit={handleLogEdit}
              />
          ))}
          <ScrollToTop showBelow={250} />
          <Box align="center">
            {logs.pagination.totalPage <= logs.pagination.currentPage ? (
              <Typography style={{ color: "teal" }} variant="body2">
                No more posts
              </Typography>
            ) : (
              " "
              // <Button
              //   variant="contained"
              //   color="primary"
              //   onClick={(e) => handleFetchPosts(logs.pagination.currentPage)}
              // >
              //   More Posts
              // </Button>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
}
