import React, { useState, useEffect, useCallback } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Autocomplete,
    Checkbox,
    Stack,
    Tooltip,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import { supabaseClient } from "../supabase";

import { UserEditorDialog } from './UserEditorDialog';

const MembersTable = ({ rows, setRows, relationships, roles, onNewRole }) => {
    // const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openEditor, setOpenEditor] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openInviteConfirm, setOpenInviteConfirm] = useState(false);
    const [history, setHistory] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    console.log('MembersTabe rows', rows);
    const recipients_id = localStorage.recipient_id;

    useEffect(() => {
        const handleUndo = (event) => {
            if (event.ctrlKey && event.key === 'z') {
                undo();
            }
        };
        document.addEventListener('keydown', handleUndo);
        return () => {
            document.removeEventListener('keydown', handleUndo);
        };
    }, [history]);

    const addRow = (newRow) => {
        setHistory([...history, { rows }]);

        var db_newRow = { ...newRow };
        if (!db_newRow.role) {
            db_newRow.role = []
        } else
            db_newRow.role.forEach((item) => {
                delete item['members']
            })

        supabaseClient.from('community').insert({ ...newRow, recipients_id: recipients_id }).select().single().then(({ data, error }) => {
            console.log("INSERTERD", data, error);
            setRows((prevRows) => [...prevRows, { id: data.id, ...newRow }]);
        });
        
    };

    const updateRow = (id, updatedFields) => {
        setHistory([...history, { rows }]);

        var db_updatedFields = { ...updatedFields };
        db_updatedFields.role.forEach((item) => {
            delete item['members']
        })
        console.log("db_updatedFields", db_updatedFields);
        supabaseClient.from('community').update(db_updatedFields).match({ id: id }).select().single().then(({ data, error }) => {
            console.log("UPDATED MEMBER", data, error);
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? { ...row, ...updatedFields } : row))
            );
        });

        if (updatedFields.profiles_id) {
            // console.log("MEMBER UPDATED", updatedFields.relationship, relationships);
            const relationship = relationships.find((item) => item.name === updatedFields.relationship);
            if (relationship) {
                supabaseClient.from('recipient_users').update({ relatinship_types_id: relationship.id }).eq('profiles_id', updatedFields.profiles_id).eq('recipients_id', recipients_id).select().single().then(({ data, error }) => { });
            }
        }


    };

    const deleteRow = (id) => {
        setHistory([...history, { rows }]);

        supabaseClient.from('community').delete().match({ id: id }).select().single().then(({ data, error }) => {
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })

        const member = rows.find((item) => item.id === id);
        if (member.profiles_id) {
            supabaseClient.from('recipient_users').update({ 'active': false }).eq('profiles_id', member.profiles_id).eq('recipients_id', recipients_id)
        }
    };

    const undo = () => {
        if (history.length > 0) {
            const previousState = history.pop();
            setRedoStack([...redoStack, rows]);
            setRows(previousState.rows);
            setHistory([...history]);
        }
    };

    const handleOpenEditor = (row) => {
        setSelectedRow(row);
        setOpenEditor(true);
    };

    const handleCloseEditor = () => {
        setSelectedRow(null);
        setOpenEditor(false);
    };

    const handleSaveEditor = (newData) => {
        if (selectedRow) {
            updateRow(selectedRow.id, newData);
        } else {
            addRow(newData);
        }
        handleCloseEditor();
    };

    const handleOpenDeleteConfirm = (row) => {
        setSelectedRow(row);
        setOpenDeleteConfirm(true);
    };

    const handleCloseDeleteConfirm = () => {
        setSelectedRow(null);
        setOpenDeleteConfirm(false);
    };

    const handleConfirmDelete = () => {
        deleteRow(selectedRow.id);
        handleCloseDeleteConfirm();
    };

    const handleOpenInviteConfirm = (row) => {
        setSelectedRow(row);
        setOpenInviteConfirm(true);
    };

    const handleCloseInviteConfirm = () => {
        setSelectedRow(null);
        setOpenInviteConfirm(false);
    };

    const handleInviteConfirm = () => {
        console.log('Invitation Confirmation', selectedRow);
        // Send invidation for a new user.
        
        const my_profile = JSON.parse(localStorage.profile);

        const newMember = {
            first_name: newRow.firstName,
            last_name: newRow.lastName,
            mobile_phone: newRow.phone,
            username: newRow.email,
            recipients_id: recipients_id,
            poa_profile_id: my_profile.id,  
        }
        supabaseClient.functions.invoke("addrecipientuser", { body: newMember });

        setOpenInviteConfirm(false);

    }

    const columns = [
        { field: 'firstName', headerName: 'First Name', editable: true, width: 150 },
        { field: 'lastName', headerName: 'Last Name', editable: true, width: 150 },
        {
            field: 'relationship',
            headerName: 'Relationship',
            editable: true,
            width: 150,
            type: 'singleSelect',
            valueOptions: relationships,
            getOptionValue: (option) => option.name,
            getOptionLabel: (option) => option.name,
        },
        { field: 'email', headerName: 'Email', editable: true, width: 200 },
        { field: 'phone', headerName: 'Phone', editable: true, width: 150 },
        {
            field: 'role',
            headerName: 'Role',
            editable: true,
            width: 150,
            type: 'singleSelect',
            valueOptions: roles,
            renderCell: (params) => {
                return params.value?.map((item) => item.ServiceNeeds).join(', ')
                // console.log('params', params.value, params);
                // params.value?.join(', ')
            },
        },

        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            type: 'actions',
            getActions: (params) => [
                (!params.row?.profiles_id) ? <GridActionsCellItem
                    icon={<PersonAddIcon />}
                    label="Invite"
                    color='success'
                    onClick={() => handleOpenInviteConfirm(params.row)}
                /> : <></>,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    color='secondary'
                    onClick={() => handleOpenEditor(params.row)}
                />,
                (params.row.relationship !== "PC") ? <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    color='error'
                    onClick={() => handleOpenDeleteConfirm(params.row)}
                /> : <></>,
            ],
        },
    ];

    return (
        <>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenEditor(null)}
                style={{ marginBottom: 16 }}
            >
                Add User
            </Button>
            <DataGrid
                rows={rows}
                columns={columns}
                // pageSize={5}
                // checkboxSelection
                disableSelectionOnClick
                // editMode="row"
                hideFooterPagination
                hideFooterSelectedRowCount
            />

            {openEditor && (
                <UserEditorDialog
                    open={openEditor}
                    onClose={handleCloseEditor}
                    onSave={handleSaveEditor}
                    initialData={selectedRow || {}}
                    relationships={relationships}
                    roles={roles}
                />
            )}

            {openDeleteConfirm && (
                <DeleteConfirmDialog
                    open={openDeleteConfirm}
                    onClose={handleCloseDeleteConfirm}
                    onConfirm={handleConfirmDelete}
                />
            )}

            {openInviteConfirm && (
                <InviteConfirmDialog
                    open={openInviteConfirm}
                    onClose={handleCloseInviteConfirm}
                    onConfirm={handleInviteConfirm}
                    currentRow={selectedRow}
                />
            )}

        </>
    );
};


const DeleteConfirmDialog = ({ open, onClose, onConfirm }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this user?</DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} variant="contained" color="secondary">
                Delete
            </Button>
        </DialogActions>
    </Dialog>
);

const InviteConfirmDialog = ({ open, onClose, onConfirm, currentRow }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Invite Confirmation</DialogTitle>
        <DialogContent>Invite {currentRow.firstName} to become a SeniorSynCare user for your community?</DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} variant="contained" color="secondary">
                Send!
            </Button>
        </DialogActions>
    </Dialog>
);

export default MembersTable;

