import React, { useState, useContext, useEffect } from "react";

import { UserContext } from "../../App";

// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
// import Profile from "../Auth/Profile";
// import Settings from "../MySenior/SeniorEntry";
// import UsersTable from "../Users/UsersTable";
import MembersTable from "./MembersTable";
import RolesTable from "./RolesTable";
// import RelationshipsTable from "../Relationships/RelationshipsTable";
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

import RelationshipList from "./RelationshipsList";

import { DefaultServiceNeeds } from "./resources";

import { supabaseClient } from "../supabase";

// import ServicesTable from "../MySenior/ServicesTable";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';


// import RegistrationLink from "../Auth/RegistrationLink";


import { Tabs, Tab, Box, Typography } from '@mui/material';
import { set } from "date-fns";


const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};


// const relationships = [
//   { icon: <PersonIcon />, name: 'Caregiver', description: 'A Caregiver' },
//   { icon: <PersonIcon />, name: 'Friend', description: 'A close personal friend' },
//   { icon: <GroupIcon />, name: 'Colleague', description: 'Someone you work with' },
//   { icon: <FamilyRestroomIcon />, name: 'Family', description: 'A family member' },
// ];


const CommunitySettings = ({ themeFontSize, onFontSizeChange }) => {
  const { userState } = useContext(UserContext);
  const isPC = (userState.currentUser.relationship.name == "PC");

  const [relationships, setRelationships] = useState([]);
  const [roles, setRoles] = useState([]);
  const [community, setCommunity] = useState([]);

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleUpdateRelationship = (relationship) => {
    console.log('Update relationship:', relationship);
  }

  useEffect(() => {
    console.log("Load Community")

    supabaseClient
      .from('recipients').select('id,ServiceNeeds').then((recipientData) => {
        console.log("recipientData", recipientData)
        var ServiceNeeds = recipientData.data[0]?.ServiceNeeds;
        console.log("setRoles ServiceNeeds", ServiceNeeds)
        if (ServiceNeeds) {
          setRoles(ServiceNeeds);
        }
        else {
          setRoles(DefaultServiceNeeds);
        }

        supabaseClient
          .from('community')
          .select('*').order('id').then((data) => {

            const myCommunity = data.data.map((item) => {
              return { ...item, role: ((item.role) ? item.role : []) }
            })

            setCommunity(myCommunity);
            console.log('Set Community:', myCommunity);

            // setRows([...mydata, createEmptyRow()]);
            supabaseClient
              .from('relationship_types')
              .select('id,icon,name,description').order('id').then((db_relationships) => {
                setRelationships(db_relationships.data);
                // const myrelationshipTypes = relationships.map((item) => item.name);
                // setRelationshipTypes(myrelationshipTypes)
              });


          })


      });





    // supabaseClient
    //   .from('community')
    //   .select('*').order('id').then((data) => {
    //     const myCommunity = data.data.map((item) => {
    //       return { ...item, role: ((item.role) ? item.role : []) }
    //     })

    //     setCommunity(myCommunity);
    //     console.log('Set Community:', myCommunity);

    //     // setRows([...mydata, createEmptyRow()]);
    //     supabaseClient
    //       .from('relationship_types')
    //       .select('id,icon,name,description').order('id').then((db_relationships) => {
    //         setRelationships(db_relationships.data);
    //         // const myrelationshipTypes = relationships.map((item) => item.name);
    //         // setRelationshipTypes(myrelationshipTypes)
    //       });

    //     supabaseClient
    //       .from('recipients').select('id,ServiceNeeds').then((recipientData) => {
    //         console.log("recipientData", recipientData)
    //         var ServiceNeeds = recipientData.data[0]?.ServiceNeeds;
    //         if (ServiceNeeds) {
    //           // const myresources = myCommunity?.map((item) => {
    //           //   const roles = item.role.split(",");
    //           //   roles.forEach((role) => {
    //           //     const index = ServiceNeeds.findIndex((x) => x.ServiceNeeds === role);
    //           //     if (index > -1) {
    //           //       ServiceNeeds[index].members.push(item);
    //           //     } else {
    //           //       ServiceNeeds.push({
    //           //         id: ServiceNeeds.length + 1,
    //           //         ServiceNeeds: role,
    //           //         Resource: [item],
    //           //         Notes: ""
    //           //     });
    //           //     }
    //           //   })
    //           // });

    //           // ServiceNeeds = ServiceNeeds.map((item) => ({ ...item, members: [] }));
    //           console.log("setRoles ServiceNeeds", ServiceNeeds)
    //           setRoles(ServiceNeeds);

    //           // const myRoles = ServiceNeeds.map((item) => item.ServiceNeeds);
    //           // setAvailableRoles(myRoles);
    //         }
    //       })
    //   });

    // }, [])
  }, []);


  useEffect(() => {
    console.log("Community updated", community);

    if (tabIndex === 0) {

      var newRoles = roles.map((item) => ({ ...item, members: [] }));

      community.forEach((member) => {
        if (!member.role) {
          return;
        }
        member.role.forEach((member_role) => {
          const index = newRoles.findIndex((x) => x.ServiceNeeds === member_role.ServiceNeeds);
          if (index > -1) {
            newRoles[index].members.push(member);
          } else {
            newRoles.push({ ...member_role, members: [member] });
          }
        });
      });
      console.log("newRoles", newRoles)
      setRoles(newRoles);
    }
  }, [community]);

  // useEffect(() => {

  //   console.log("Roles updated", roles);
  //   if (tabIndex === 1) {

  //     var newCommunity = community.map((item) => ({ ...item, role: [] }));
  //     roles.forEach((role) => {
  //       if (!role.members) {
  //         return;
  //       }
  //       role.members.forEach((member) => {
  //         const index = newCommunity.findIndex((x) => x.id === member.id);
  //         if (index > -1) {
  //           newCommunity[index].role.push(role);
  //         } else {
  //           newCommunity.push({ ...member, role: [role] });
  //         }
  //       });
  //     });
  //     setCommunity(newCommunity);
  //   }
  // }, [roles]);


  useEffect(() => {

    console.log("Roles updated", roles);
    if (tabIndex === 1) {

      var newCommunity = community.map((item) => ({ ...item, role: [] }));      
      roles.forEach((role) => {
        if (!role.members) {
          return;
        }
        role.members.forEach((member) => {
          const index = newCommunity.findIndex((x) => x.id === member.id);
          if (index > -1) {
            newCommunity[index].role.push(role);
          } else {
            newCommunity.push({ ...member, role: [role] });
          }
        });
      });
      setCommunity(newCommunity);
    }
  }, [roles]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={3}>
        <RelationshipList relationships={relationships} onUpdateRelationship={handleUpdateRelationship} />
        <Stack spacing={0}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="User and Role Tabs">
            <Tab label="Members" sx={{
              border: tabIndex === 0 ? '2px solid' : 'none',
              borderRadius: '4px',
              borderColor: 'primary.main',
            }} />
            <Tab label="Roles" sx={{
              border: tabIndex === 1 ? '2px solid' : 'none',
              borderRadius: '4px',
              borderColor: 'primary.main',
            }} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <MembersTable
              rows={community}
              setRows={setCommunity}
              relationships={relationships}
              roles={roles}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <RolesTable
              rows={roles}
              setRows={setRoles}
              community={community}
              relationships={relationships}
            />
          </TabPanel>
        </Stack>
      </Stack>
    </Box>
  );
};


export default CommunitySettings;

