import React, { useState, useEffect } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Autocomplete,
  Checkbox,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import { supabaseClient } from "../supabase";
import { ConstructionOutlined } from '@mui/icons-material';
import { UserEditorDialog } from './UserEditorDialog';


const RolesTable = ({ rows, setRows, community, relationships, onNewMember }) => {
  // const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  console.log("RolesTable rows", rows);
  const recipients_id = localStorage.recipient_id;

  useEffect(() => {
    const handleUndo = (event) => {
      if (event.ctrlKey && event.key === 'z') {
        undo();
      }
    };
    document.addEventListener('keydown', handleUndo);
    return () => {
      document.removeEventListener('keydown', handleUndo);
    };
  }, [history]);

  const addRow = (newRow) => {
    setHistory([...history, { rows }]);

    const maxId = rows.reduce((max, dict) => Math.max(max, dict.id), 0);

    var db_rows = [...rows, { ...newRow, id: maxId + 1 }];
    // db_rows.forEach((row) => {
    //   delete row.members;
    // })
    supabaseClient.from('recipients').update({ ServiceNeeds: db_rows }).eq('id', recipients_id).single().select().then((data) => {
      console.log('addRow data', data);
    });

    newRow.members.forEach((member) => {
      const community_member = community.find((x) => x.id === member.id);
      if (community_member) {
        var db_role = {...newRow.members};
        delete db_role.members;
        const new_roles = [...community_member.role, { id: maxId + 1, ...db_role }];
        console.log('addRow update new_roles', community_member.id, new_roles);
        supabaseClient.from('community').update({ role: new_roles }).eq('id', community_member.id).single().select().then((data) => {
          // console.log('addRow data', data);
        });
      } else{
        supabaseClient.from('community').select('id,role').eq('id', member.id).single().then(({ data, error }) => {
          // console.log('addRow select data', data, error);
          const db_role = { ...newRow };
          delete db_role.members;
          const new_roles = [...data.role, { id: maxId + 1, ...db_role }];
          console.log('addRow add new_roles', member.id, new_roles);
          supabaseClient.from('community').update({ role: new_roles }).eq('id', member.id).single().select().then((data) => {
            // console.log('addRow data', data);
          });
        });
      }
    })

    setRows((prevRows) => [...prevRows, { id: maxId + 1, ...newRow }]);
  };

  const updateRow = (id, updatedFields) => {
    setHistory([...history, { rows }]);

    community.forEach((member) => {
      // if the community member does not have this role, and is in the updatedFields.members, then add the role to the member
      const role = member?.role.find((x) => x.id === id);
      const community_member = updatedFields.members.find((x) => x.id === member.id);
      if (role && !community_member) {
      // if the community member has this role, and is not in the updatedFields.members, then remove the role from the member
        // console.log('updateRow role and !community_member. Remove role from community member.', role, community_member);
        const new_roles = member.role.filter((x) => x.id !== id);
        // console.log('updateRow remove new_roles', member.id, new_roles);
        supabaseClient.from('community').update({ role: new_roles }).eq('id', member.id).single().select().then((data) => {
          // console.log('updateRow addRow response', data);
        });
      }
      else if (!role && community_member) {
        // console.log('updateRow !role and community_member. Add role to the member.', role, community_member);
        const db_UpdatedFields = { ...updatedFields };
        delete db_UpdatedFields.members;
        const new_roles = [...member.role, { id:id, ...db_UpdatedFields} ];
        // console.log('updateRow update new_roles', member.id, new_roles);
        supabaseClient.from('community').update({ role: new_roles }).eq('id', member.id).single().select().then((data) => {
          // console.log('updateRow addRow response', data);
        });
      } else{
        // if (role && community_member) {
        //   // if the community member has this role, and is in the updatedFields.members, then do nothing
        //   console.log('updateRow role and community_member. Do nothing.', role, community_member);
        // }
      }
    });


    console.log("UPDATE ROW", updatedFields);
    console.log("OLD ROS", rows); 

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, ...updatedFields } : row))
    );

    var db_rows = rows.map((row) => (row.id === id ? { ...row, ...updatedFields } : row));
    const role_members = [ ...updatedFields.members ];

    // db_rows.forEach((row) => {
    //   delete row.members;
    // })
    supabaseClient.from('recipients').update({ ServiceNeeds: db_rows }).eq('id', recipients_id).single().select().then((data) => {
      // console.log('updatefor addRows data', data);
    });



  };

  const deleteRow = (id) => {
    setHistory([...history, { rows }]);

    var db_rows = rows.filter((row) => row.id !== id);
    db_rows.forEach((row) => {
      delete row.members;
    })
    supabaseClient.from('recipients').update({ ServiceNeeds: db_rows }).eq('id', recipients_id).single().select().then((data) => {
      console.log('addRow data', data);
    });

    community.forEach((member) => {
      const role = member.role.find((x) => x.id === id);
      if (role) {
        const new_roles = member.role.filter((x) => x.id !== id);
        console.log('deleteRow remove new_roles', member.id, new_roles);
        supabaseClient.from('community').update({ role: new_roles }).eq('id', member.id).single().select().then((data) => {
          console.log('deleterow data', data);
        });
      }
    });

    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const undo = () => {
    if (history.length > 0) {
      const previousState = history.pop();
      setRedoStack([...redoStack, rows]);
      setRows(previousState.rows);
      setHistory([...history]);
    }
  };

  const handleOpenEditor = (row) => {
    console.log('handleOpenEditor', row);
    setSelectedRow(row);
    setOpenEditor(true);
  };

  const handleCloseEditor = () => {
    setSelectedRow(null);
    setOpenEditor(false);
  };

  const handleSaveEditor = (newData) => {
    if (selectedRow) {
      updateRow(selectedRow.id, newData);
    } else {
      addRow(newData);
    }
    handleCloseEditor();
  };

  const handleOpenDeleteConfirm = (row) => {
    setSelectedRow(row);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirm = () => {
    setSelectedRow(null);
    setOpenDeleteConfirm(false);
  };

  const handleConfirmDelete = () => {
    deleteRow(selectedRow.id);
    handleCloseDeleteConfirm();
  };

  const columns = [
    { field: 'ServiceNeeds', headerName: 'Role', editable: true, width: 350 },
    {
      field: 'members',
      headerName: 'Members',
      editable: true,
      width: 250,
      renderCell: (params) => params.value?.map((item) => item.firstName).join(', ')
    },
    { field: 'Notes', headerName: 'Notes', editable: true, width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleOpenEditor(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleOpenDeleteConfirm(params.row)}
        />,
      ],
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => handleOpenEditor(null)}
        style={{ marginBottom: 16 }}
      >
        Add Role
      </Button>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // checkboxSelection
        disableSelectionOnClick
        hideFooterPagination
        hideFooterSelectedRowCount
      />

      {openEditor && (
        <RoleEditorDialog
          open={openEditor}
          onClose={handleCloseEditor}
          onSave={handleSaveEditor}
          community={community}
          relationships={relationships}
          initialData={selectedRow || {}}
        />
      )}

      {openDeleteConfirm && (
        <DeleteConfirmDialog
          open={openDeleteConfirm}
          onClose={handleCloseDeleteConfirm}
          onConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
};




const RoleEditorDialog = ({ open, onClose, onSave, initialData, community, relationships }) => {
  const [formData, setFormData] = useState(initialData);

  const [openNewMemberEditor, setOpenNewMemberEditor] = useState(false);

  console.log('RoleEditorDialog initialData', initialData);
  console.log('RoleEditorDialog community', community);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const recipients_id = localStorage.recipient_id;
  
  const onNewMemberSave = (newData) => {
    console.log('onNewMemberSave', newData);

    supabaseClient.from('community').insert({ ...newData, recipients_id: recipients_id }).select('id,firstName,lastName').single().then(({ data, error }) => {
      console.log("INSERTERD", data, error);
      // setRows((prevRows) => [...prevRows, { id: data.id, ...newRow }]);
      const newMember = { id: data.id, ...newData };
      setFormData((prevData) => ({ ...prevData, members: prevData.members ? [...prevData.members, newMember] : [newMember] }));
      setOpenNewMemberEditor(false);
  
    });
  }

  const handleNewMemberOpen = () => {
    setOpenNewMemberEditor(true);
  }


  return (
    <>
      <Dialog open={open} onClose={onClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "600px!important",
          },
        }}
      >
        <DialogTitle>{initialData?.id ? 'Edit Role' : 'Add Role'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Role"
            value={formData.ServiceNeeds || ''}
            onChange={(e) => handleChange('ServiceNeeds', e.target.value)}
            fullWidth
            margin="normal"
          />
          <Autocomplete
            multiple
            id="members-standard"
            options={community}
            getOptionLabel={(option) => option.firstName + " " + option.lastName}
            // getOptionValue={(option) => option.ServiceNeeds}
            //   defaultValue={[sharingOptions[0]]}
            value={formData?.members || []}
            defaultValue={[]}
            // readOnly={readOnly}
            // limitTags={4}
            // groupBy={(option) => {
            //   option.group;
            // }}
            disableCloseOnSelect
            // onChange={(e, value) => { handleChange(value) }}
            onChange={(event, newValue) => handleChange('members', newValue)}
            renderOption={(props, option, { selected }) => (

              <li {...props}>
                <Checkbox
                  // icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.firstName + " " + option.lastName}
                {/* {option.title} */}
              </li>
            )}
            renderInput={(params) => (
              <Stack direction={"row"} spacing={1}>
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Members"}
                />
                <Tooltip title="New Member...">
                  <IconButton edge="end" color="primary"
                    onClick={() => { setOpenNewMemberEditor(true) }}>
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          />
          <TextField
            label="Notes"
            value={formData.Notes || ''}
            onChange={(e) => handleChange('Notes', e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => onSave(formData)} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <UserEditorDialog
        open={openNewMemberEditor}
        onClose={() => setOpenNewMemberEditor(false)}
        onSave={onNewMemberSave}
        relationships={relationships}
        roles={[]} />
    </>
  );
};

const DeleteConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>Are you sure you want to delete this role?</DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} variant="contained" color="secondary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default RolesTable;
