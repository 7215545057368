import React, { useState, useCallback, useEffect } from 'react';
import { DataGrid, GridRowEditStartReasons, GridRowEditStopReasons, GridCellModes, useGridApiRef } from '@mui/x-data-grid';
import { Autocomplete, Checkbox, TextField, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import { set } from 'date-fns';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { supabaseClient } from "../supabase";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const roleOptions = ['Admin', 'Editor', 'Viewer', 'Contributor', 'Moderator'];
const serviceNeedsList = [
    "Getting in and out of bed",
    "Dressing",
    "Grooming",
    "Toileting",
    "Eating",
    "Walking",
    "Getting out of chair",
    "Preparing Meals",
    "Doing laundry",
    "Light housework",
    "Shopping ",
    "Transportation",
    "Using telephone",
    "Managing money",
    "Administering medications ",
    "Exercise",
    "Social Events",
    "Making and attending medical appointments",
    "Emotional/Spiritual"
]

const ServicesTable = () => {
    const createEmptyRow = () => ({
        id: Date.now(),
        ServiceNeeds: '<New>',
        Member: [],
        Notes: '',
    });

    const [rows, setRows] = useState([...initialData, createEmptyRow()]);
    const [history, setHistory] = useState([]);
    const [community, setCommunity] = useState([]);
    const [currentStep, setCurrentStep] = useState(-1);
    const [snackbar, setSnackbar] = React.useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [rowToDelete, setRowToDelete] = React.useState(null);

    const [resources, setResources] = useState([]);

    const [openResourceModal, setOpenResourceModal] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState(null);
    const [availableRoles, setAvailableRoles] = React.useState(roleOptions);

    const apiRef = useGridApiRef();

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleUndo = () => {
        if (currentStep < 0) return;

        const { id, field, oldValue } = history[currentStep];
        const newRows = rows.map(row =>
            row.id === id ? { ...row, [field]: oldValue } : row
        );

        setRows(newRows);
        setCurrentStep(currentStep - 1);
    };

    const handleKeyDown = useCallback((event) => {
        if (event.ctrlKey && event.key === 'z') {
            handleUndo();
        }
    }, [handleUndo]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        console.log("LOad Community")

        supabaseClient
            .from('recipients').select('id,ServiceNeeds').then((recipientData) => {
                console.log("recipientData", recipientData)
                var ServiceNeeds = recipientData.data[0].ServiceNeeds.map((item) => ({ ...item, 'Resource': [] }));

                supabaseClient
                    .from('community')
                    .select('*').order('id').then((mycommunity) => {

                        // const mydata = data.map((item) => {
                        //     return { ...item, role: ((item.role) ? item.role.split(",") : []) }
                        // })

                        console.log("COMMUNITY", mycommunity?.data)
                        setCommunity(mycommunity?.data)
                        const myresources = mycommunity?.data.map((item) => {
                            const resource = item.firstName + " " + item.lastName;
                            console.log('')
                            if (item.role) {
                                const roles = item.role.split(",");
                                roles.forEach((role) => {
                                    const index = ServiceNeeds.findIndex((x) => x.ServiceNeeds === role);
                                    if (index > -1) {
                                        ServiceNeeds[index].Resource.push(resource);
                                    } else {
                                        ServiceNeeds.push({
                                            id: ServiceNeeds.length + 1,
                                            ServiceNeeds: role,
                                            Resource: [resource],
                                            Notes: ""
                                        });
                                    }
                                });
                            }
                            return resource
                        })
                        setResources(myresources);
                        console.log("SERVICE NEEDS", ServiceNeeds)
                        setRows([...ServiceNeeds, createEmptyRow()]);



                        //   setRows([...mydata, createEmptyRow()]);
                        //   loadRelationships().then((relationships) => {
                        //     const myrelationshipTypes = relationships.map((item) => item.name);
                        //     setRelationshipTypes(myrelationshipTypes)
                        //   });

                    });
            });

        // }, [])
    }, []);

    function CustomEditComponent(props) {
        const { id, value, field, hasFocus } = props;
        const ref = React.useRef();

        React.useLayoutEffect(() => {
            if (hasFocus) {
                ref.current.focus();
            }
        }, [hasFocus]);

        const handleValueChange = (event) => {
            const newValue = event.target.value; // The new value entered by the user
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return <input ref={ref} type="text" value={value == '<New>' ? '' : value} onChange={handleValueChange} placeholder={value == '<New>' ? value : ''} />;
    }


    const handleRoleChange = (id, newRoles) => {
        setRows((prevRows) =>
            prevRows.map(row => (row.id === id ? { ...row, role: newRoles } : row))
        );
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleCellEditStart = (params, event) => {
        // console.log('handleCellEditStart', params, event, GridRowEditStartReasons);
        if (params.field === 'Resource') {
            handleOpenResourceModal(params.row)
        }
    }

    const processRowUpdate = (newRow, oldRow) => {
        const updatedRows = rows.map(row => (row.id === newRow.id ? newRow : row));

        const fieldChanged = Object.keys(newRow).find(key => newRow[key] !== oldRow[key]);

        if (fieldChanged && newRow.firstName !== '<New>') {
            const newHistory = [
                ...history.slice(0, currentStep + 1),
                { id: newRow.id, field: fieldChanged, oldValue: oldRow[fieldChanged], newValue: newRow[fieldChanged] }
            ];
            setHistory(newHistory);
            setCurrentStep(newHistory.length - 1);
        }

        setRows(updatedRows);

        const lastRow = rows[rows.length - 1];
        if (newRow.id === lastRow.id) {
            setRows([...updatedRows, createEmptyRow()]);
        }

        return newRow;
    };


    const handleDoneResourceModal = () => {
        console.log("handleDoneResourceModal", currentRow)
        setRows((prevRows) =>
            prevRows.map(row => (row.id === currentRow.id ? { ...row, Resource: currentRow.Resource } : row))
        );

        // updateRecord[fieldChanged] = newRow[fieldChanged].join(',');

        // const { data: record, error } = supabaseClient.from('community').update({ role: currentRow.role.join(',') }).eq('id', currentRow.id).then((data) => {
        // const newHistory = [
        //     ...history.slice(0, currentStep + 1),
        //     { id: currentRow.id, field: fieldChanged, oldValue: oldRow[fieldChanged], newValue: newRow[fieldChanged] }
        // ];
        // setHistory(newHistory);
        // setCurrentStep(newHistory.length - 1);
        // });
        setOpenResourceModal(false);
    };

    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleDeleteClick = (id) => {
        setRowToDelete(id);
        setOpenConfirmDialog(true);
    };

    const confirmDeleteRow = () => {
        setRows(rows.filter((row) => row.id !== rowToDelete));
        setRowToDelete(null);
        setOpenConfirmDialog(false);
    };

    const cancelDelete = () => {
        setRowToDelete(null);
        setOpenConfirmDialog(false);
    };

    const handleOpenResourceModal = (row) => {
        setCurrentRow(row);
        setOpenResourceModal(true);
    };

    const handleCloseResourceModal = () => {
        setOpenResourceModal(false);
    };

    const columns = [
        {
            field: 'ServiceNeeds', headerName: 'Service Need', editable: true, width: 300,
            renderEditCell: (params) => (
                <CustomEditComponent {...params} />
            ),
        },
        { field: 'Resource', headerName: 'Members', editable: true, width: 300 },
        { field: 'Notes', headerName: 'Notes', editable: true, width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <IconButton color="secondary" onClick={() => handleDeleteClick(params.id)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const handleResourceChange = (newResources) => {
        // console.log("handleRoleChange", newRoles);

        setCurrentRow({ ...currentRow, Resource: newResources });

        // setRows((prevRows) =>
        //   prevRows.map(row => (row.id === id ? { ...row, role: newRoles } : row))
        // );
        // setCurrentRow({ ...currentRow, role: newRoles });
        // currentRow.role = newRoles;

    };



    return (
        <>
            <DataGrid
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                processRowUpdate={processRowUpdate}
                autoHeight
                pageSize={5}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onRowEditStop={handleRowEditStop}

                onCellEditStart={handleCellEditStart}
                hideFooterPagination
                hideFooterSelectedRowCount
            />

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}

            <Dialog open={openConfirmDialog} onClose={cancelDelete}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this row? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={cancelDelete} color="primary">
                        <CancelIcon />
                    </IconButton>
                    <IconButton onClick={confirmDeleteRow} color="secondary">
                        <DoneIcon />
                    </IconButton>
                </DialogActions>
            </Dialog>

            {/* Resource Editor Modal */}
            <Dialog PaperProps={{
                sx: {
                    width: "70%",
                    maxWidth: "720px!important",
                },
            }} open={openResourceModal} onClose={handleCloseResourceModal}>
                <DialogTitle>Edit Resources</DialogTitle>
                <DialogContent>
                    {/* <Autocomplete
            multiple
            freeSolo
            options={availableRoles}
            value={currentRow?.role || []}
            onChange={(event, newValue) => handleRoleChange(currentRow.id, newValue)}
            renderInput={(params) => <TextField {...params} label="Roles" placeholder="Add or select roles" />}
          /> */}


                    <Autocomplete
                        multiple
                        id="roles-standard"
                        options={resources}
                        // getOptionLabel={(option) => option.title}
                        //   defaultValue={[sharingOptions[0]]}
                        value={currentRow?.Resource || []}
                        defaultValue={"New Resource"}
                        // readOnly={readOnly}
                        limitTags={4}
                        // groupBy={(option) => {
                        //   option.group;
                        // }}
                        disableCloseOnSelect
                        // onChange={(e, value) => { handleChange(value) }}
                        onChange={(event, newValue) => handleResourceChange(newValue)}
                        renderOption={(props, option, { selected }) => (

                            <li {...props}>
                                <Checkbox
                                    // icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                                {/* {option.title} */}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={"Resources"}
                            //   placeholder="Favorites"
                            />
                        )}
                    // renderGroup={(params) => (
                    //   <li key={params.key}>
                    //     <GroupHeader>{params.group}</GroupHeader>
                    //     <GroupItems>{params.children}</GroupItems>
                    //   </li>
                    // )}
                    />
                </DialogContent>
                <DialogActions>

                    <Button
                        component="label"
                        // role={undefined}
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                    // onClick={handleNewResourceDialogOpen} color="secondary"
                    >
                        New Resource</Button>
                    <Button
                        component="label"
                        // role={undefined}
                        variant="contained"
                        startIcon={<DoneIcon />}
                        onClick={handleDoneResourceModal} color="primary"
                    >
                        Done</Button>

                    <Button
                        component="label"
                        // role={undefined}
                        variant="contained"
                        startIcon={<CancelIcon />}
                        onClick={handleCloseResourceModal} color="secondary"
                    >
                        Cancel</Button>
                </DialogActions>
            </Dialog >



        </>
    );
};

// Sample initial data
const initialData = [
    // { "id": 1, "ServiceNeeds": "Getting in and out of bed", "Resource": "", "Notes": "" },
    // { "id": 2, "ServiceNeeds": "Dressing", "Resource": "", "Notes": "" },
    // { "id": 3, "ServiceNeeds": "Grooming", "Resource": "", "Notes": "" },
    // { "id": 4, "ServiceNeeds": "Toileting", "Resource": "", "Notes": "" },
    // { "id": 5, "ServiceNeeds": "Eating", "Resource": "", "Notes": "" },
    // { "id": 6, "ServiceNeeds": "Walking", "Resource": "", "Notes": "" },
    // { "id": 7, "ServiceNeeds": "Getting out of chair", "Resource": "", "Notes": "" },
    // { "id": 8, "ServiceNeeds": "Preparing Meals", "Resource": "", "Notes": "" },
    // { "id": 9, "ServiceNeeds": "Doing laundry", "Resource": "", "Notes": "" },
    // { "id": 10, "ServiceNeeds": "Light housework", "Resource": "", "Notes": "" },
    // { "id": 11, "ServiceNeeds": "Shopping ", "Resource": "", "Notes": "" },
    // { "id": 12, "ServiceNeeds": "Transportation", "Resource": "", "Notes": "" },
    // { "id": 13, "ServiceNeeds": "Using telephone", "Resource": "", "Notes": "" },
    // { "id": 14, "ServiceNeeds": "Managing money", "Resource": "", "Notes": "" },
    // { "id": 15, "ServiceNeeds": "Administering medications ", "Resource": "", "Notes": "" },
    // { "id": 16, "ServiceNeeds": "Exercise", "Resource": "", "Notes": "" },
    // { "id": 17, "ServiceNeeds": "Social Events", "Resource": "", "Notes": "" },
    // { "id": 18, "ServiceNeeds": "Making and attending medical appointments", "Resource": "", "Notes": "" },
    // { "id": 19, "ServiceNeeds": "Emotional/Spiritual", "Resource": "", "Notes": "" },
];

export default ServicesTable;
