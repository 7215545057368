import React, { useContext, useState } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { loadRelationships } from "../Relationships/useRelationships";
import { loadUsers } from "../Users/useUsers";

import axios from "axios";

import { supabaseClient } from "../supabase";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import validator from "@rjsf/validator-ajv6";

import SelectSiteModal from "./selectSiteModal";

import { UIContext, UserContext } from "../../App";

// const url = 'http://192.168.1.114:12001'
const url = window.location.protocol + '//' + window.location.hostname

const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
const base_domain = rest_hostpath.join('.');

const schema = {
  title: current_subdomain.startsWith('cop-') ? "SeniorSynCare Community of Practice Login" : "SeniorSynCare Login",
  type: "object",
  required: ["username", "password"],
  properties: {
    username: {
      type: "string",
      title: "Email",
      format: "email",
      minLength: 3,
    },
    password: {
      type: "string",
      title: "Password",
      minLength: 3,
    },
    remember: {
      type: "boolean",
      title: "Remember me",
    },
  },
};

const ui_schema = {
  username: {
    "ui:autofocus": true,
    // "ui:description": "Login Username",
  },
  password: {
    // "ui:description": "Login password",
    "ui:widget": "password",
  },
  "ui:submitButtonOptions": {
    submitText: "Login",
    norender: false,
    props: {
      disabled: false,
      className: "btn btn-info",
    },
  },
};


const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

// const asyncLocalStorage = {
//   setItem: async function (key, value) {
//     localStorage.setItem(key, value);
//   },
//   getItem: async function (key) {
//     return localStorage.getItem(key);
//   },
// };


const Login = () => {
  const { userState } = useContext(UserContext);
  const { uiDispatch } = useContext(UIContext);
  const { userDispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const { userState } = useContext(UserContext);
  const [siteList, setSiteList] = useState([]);


  // const recipientId = localStorage.recipient_id && JSON.parse(localStorage.recipient_id);
  const recipientId = localStorage.recipient_id;

  const loadUser = async (user) => {
    userDispatch({
      type: "SET_CURRENT_USER",
      payload: user,
    });

    await loadRelationships().then((data) => {
      userDispatch({
        type: "SET_RELATIONSHIPS",
        payload: data,
      });
    });

    await loadUsers().then((data) => {
      userDispatch({
        type: "SET_USERS",
        payload: data,
      });
    });

  };


  const redirect_user = async (user_data) => {

    const { data: associations_raw } = await supabaseClient.functions.invoke("profile_subdomains",
      {
        body: {
          profiles_id: user_data.id
        }
      });

    console.log("ASSOCIATIOSN RAW 2", associations_raw)

    // const { data: associations_raw } = await axios({
    //   url: `${url}/authenticated/profile_subdomains`,
    //   params: { profiles_id: user_data.id }
    // });

    const associations = associations_raw.filter((row) => row.active == true);

    // const { data: associations } = await axios({
    //   url: `${url}/authenticated/profile_subdomains`,
    //   params: { profiles_id: user_data.id }
    // });

    // const [current_subdomain, ...rest_hostpath] = window.location.hostname.split(".");
    // const base_domain = rest_hostpath.join('.');

    var current_site_association = associations.find((association) =>
      association.subdomain == current_subdomain
    );


    console.log("current_site_association", current_site_association)

    if ((current_site_association !== undefined) && (current_subdomain === current_site_association?.subdomain)) {

      const { data: profiles } = await supabaseClient.from('profiles').select('id,first_name,last_name,recipient_users(id,logins_count,relationship_types(id,name,access_logs, access_schedule))').eq('id', user_data.id);

      var profile = profiles[0];

      console.log("FIRST LOGIN NOTIFY", profile.recipient_users[0].logins_count)


      const { data, error } = await supabaseClient.functions.invoke("loginnotify",
        { body: { recipient_users_id: profile.recipient_users[0].id } });

      if (profile.recipient_users.length === 0) {
        window.location.assign(`${window.location.protocol}//${window.location.hostname}/`);
      }

      profile.relationship = profile.recipient_users[0].relationship_types;

      localStorage.setItem("profile", JSON.stringify({ ...user_data, ...profile }));

      await loadUser(profile);
      setLoading(false);
      navigate("/");
    } else {

      if (associations.length == 0) {

        await supabaseClient.auth.signOut();

        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: "Login not associated with any active sites.",
          },
        });
        setLoading(false);

      } else if (associations.length == 1) {

        const recipient_subdomain = associations[0].subdomain;

        const { data: magic_link } = await supabaseClient.functions.invoke("getmagiclink",
          {
            body: {
              email: user_data.email,
              subdomain: current_subdomain
            }
          });

        // const { data: magic_link } = await axios({
        //   url: `${url}/authenticated/getmagiclink`,
        //   params: { email: user_data.email, subdomain: current_subdomain }
        // });

        await supabaseClient.auth.signOut();

        const redirect = `${window.location.protocol}//${recipient_subdomain}.${base_domain}/login/${magic_link.hashed_token}`;
        console.log("Redirecting to:", redirect);
        window.location.assign(redirect)

      } else {

        const { data: magic_link } = await supabaseClient.functions.invoke("getmagiclink",
          {
            body: {
              email: user_data.email,
              subdomain: current_subdomain
            }
          });

        // const { data: magic_link } = await axios({
        //   url: `${url}/authenticated/getmagiclink`,
        //   params: { email: user_data.email, subdomain: current_subdomain }
        // });

        for (var i = 0; i < associations.length; i++) {
          associations[i].magic_link = magic_link;
        }

        await supabaseClient.auth.signOut();

        setSiteList(associations);
      }
    }
  }



  const formSubmit = async ({ formData }, e) => {

    setLoading(true);

    const { data, error } = await supabaseClient.auth.signInWithPassword({
      email: formData["username"],
      password: formData["password"]
    });

    if (error) {
      console.log('Login Error', error);
      uiDispatch({
        type: "SET_MESSAGE",
        payload: {
          color: "error",
          display: true,
          text: "Invalid login credentials.",
        },
      });
      setLoading(false);
    }

    if (data?.user) {
      const user_data = data.user;

      uiDispatch({
        type: "SET_MESSAGE",
        payload: {
          color: "success",
          display: true,
          text: "Successful Login",
        },
      });
      redirect_user(user_data);

    }
  }

  return (
    <>
      <SelectSiteModal sitesList={siteList} />
      <Box
        width={"50vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Paper variant="elevation" elevation={2}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            padding={2}
          >
            <Grid item>
              <Form
                schema={schema}
                uiSchema={ui_schema}
                validator={validator}
                onSubmit={formSubmit}
              >
                <div>
                  <Button
                    type="submit"
                    disabled={loading}
                    style={{
                      width: "100%",
                      // background: "rgb(24,119,242)",
                      // color: "#fff",
                      marginTop: "16px",
                      height: 70,
                    }}
                    size="large"
                    variant="contained"
                  >
                    {loading ? (
                      <CircularProgress
                        variant="indeterminate"
                        size={25}
                        style={{ color: "#fff" }}
                      />
                    ) : (
                      "Log In"
                    )}
                  </Button>
                </div>
              </Form>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/reset" variant="body2">
                Forgot Password?
              </Link>
            </Grid>
            {/* {1 == 1 && ( */}
            {((current_subdomain === "portal") || (current_subdomain.startsWith("cop-"))) && (
              <Grid item>
                <p>If not already signed up, register here:</p>
                <Box
                  width={"50vh"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  m="auto"
                >
                  <Button
                    style={{
                      width: "100%",
                      // color: 'secondary',
                      background: "#B091C3",
                      color: "#fff",
                      marginTop: "16px",
                    }}
                    size="small"
                    onClick={() => {
                      navigate("/register");
                    }}
                  // variant="outlined"
                  >
                    Register
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Login;
